import React, { useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import {useWidth} from "../../utils/dimUtils"

export const PanElement = (props) => {
  const elementRef = useRef(null);
  const width = useWidth();

  useEffect(() => {
    if (elementRef.current) {
      // Update the element width in state
      setElementWidth(elementRef.current.offsetWidth);
      console.log(elementWidth)
    }
  }, []);

  const [elementWidth, setElementWidth] = React.useState(0);

  const springConfig = {
    reset: true,
    from: { transform: "translateX(0%)", opacity: 0 },
    to: async (next) => {
      while (true) {
        const screenWidth =
          width ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

        // Slowing down the movement to the center by increasing the duration
        await next({
          transform: `translateX(${(screenWidth - 600) / 2}px)`,
          opacity: 1,
          config: { duration: 2000 }, // Smooth transition duration to 2 seconds
        });
        await new Promise((resolve) => setTimeout(resolve, 6000)); // Wait for 5 seconds

        // Slowing down the movement to the right by increasing the duration
        await next({
          transform: `translateX(${screenWidth}px)`,
          opacity: 0,
          config: { duration: 2000 }, // Smooth transition duration to 2 seconds
        });

        // Slowing down the reappearing animation by increasing the duration
        await next({
          transform: "translateX(0%)",
          opacity: 0,
          config: { duration: 1 }, // Smooth transition duration to 2 seconds
        });
      }
    },
    // Optionally adjust the spring physics for smoother animation
    config: {
      tension: 170, // Lower tension makes the spring looser
      friction: 26, // Higher friction makes the spring slower
      mass: 1, // Mass affects the spring's momentum, keep it at 1 for default behavior
    },
  };

  const animatedProps = useSpring(springConfig);

  return (
    <animated.div
      ref={elementRef}
      style={{
        width: "100px",
        height: "200px",
        position: "absolute",
        ...animatedProps,
        ...props.style
      }}
    >
      {props.children}
    </animated.div>
  );
};

