export function getDominantColor(image) {
  // Create an image data array to store the pixel data
  var imageData = new Uint8ClampedArray(image.width * image.height * 4);

  // Load the pixel data into the array
  var ctx = new ImageData(imageData, image.width, image.height);
  ctx.data.set(image.data);

  // Calculate the average color of the image
  var r = 0, g = 0, b = 0, total = 0;
  for (var i = 0; i < ctx.data.length; i += 4) {
    r += ctx.data[i];
    g += ctx.data[i + 1];
    b += ctx.data[i + 2];
    total++;
  }
  r = Math.floor(r / total);
  g = Math.floor(g / total);
  b = Math.floor(b / total);

  // Return the average color as a hex code
  return '#' + r.toString(16) + g.toString(16) + b.toString(16);
}

export function colorInterpolator(aColor, bColor, interpolator) {
    // The colors must be rgb arrays
    // Interpolator must be a number between -1 and 1 (sine and cosine)
    let mean = [];
    let radius = [];
    let result = [];
    for(let i = 0; i < 3; i++) {
        mean[i] = (aColor[i] + bColor[i]) / 2;
        radius[i] = Math.abs((aColor[i] - bColor[i]) / 2);

        result[i] = mean[i] + interpolator * radius[i];
    }
    console.log(rgbToHex(result));
    return result;
}


export function colorGradient(fadeFraction, rgbColor1, rgbColor2) {
    var color1 = rgbColor1;
    var color2 = rgbColor2;
    var fade = fadeFraction;
    
    var diffRed = color2[0] - color1[0];
    var diffGreen = color2[1] - color1[1];
    var diffBlue = color2[2] - color1[2];

    var gradient = {
      red: parseInt(Math.floor(color1[0] + (diffRed * fade)), 10),
      green: parseInt(Math.floor(color1[1] + (diffGreen * fade)), 10),
      blue: parseInt(Math.floor(color1[2] + (diffBlue * fade)), 10),
    };

    return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
  }

export function rgbToHex(color) {
    return "#" + hexifiy(color[0].toFixed(0)) + hexifiy(color[1].toFixed(0)) + hexifiy(color[2].toFixed(0));
}

export function hexToRgb(hex) {
  let res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return res ? [
    parseInt(res[1], 16),
    parseInt(res[2], 16),
    parseInt(res[3], 16)
    ] : null;
}

export function hexifiy(c) {
  let hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}
