import {Fireplace, Timer} from "@material-ui/icons";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import {IconButton} from "@mui/material";
import { useOrderStore } from "../../stores/OrderStore";

export function ChronoBtn() {
    const updateOrder = useOrderStore((state) => state.updateOrder);
    const order = useOrderStore((state) => state.order);
    const ord = "start_time_unix"

    return(
        <IconButton style={{ marginLeft: 25 }} onClick={() => { updateOrder(ord) }}> <Timer style={{ color: order === ord ? "#fff" : "#777" }}/> </IconButton>
    )
}

export function PopBtn() {
    const updateOrder = useOrderStore((state) => state.updateOrder);
    const order = useOrderStore((state) => state.order);
    const ord = "pop"

    return(
        <IconButton style={{ marginLeft: 25 }} onClick={() => { updateOrder(ord) }}> <LocalFireDepartmentIcon style={{ color: order === ord ? "#fff" : "#777" }}/> </IconButton>
    )
}

export function TopBtn() {
    const updateOrder = useOrderStore((state) => state.updateOrder);
    const order = useOrderStore((state) => state.order);
    const ord = "rank"

    return(
        <IconButton style={{ marginLeft: 25 }} onClick={() => { updateOrder(ord) }}> <VerticalAlignTopIcon style={{ color: order === ord ? "#fff" : "#777" }}/> </IconButton>
    )
}
