import { gql } from '@graphql-mesh/utils';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';
import GraphqlHandler from "@graphql-mesh/graphql";
import UsePollingLive from "@graphprotocol/client-polling-live";
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import * as importedModule$0 from "./sources/MarketPlace/introspectionSchema.json";
import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');
const importFn = (moduleId) => {
    const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
    switch (relativeModuleId) {
        case ".graphclient/sources/MarketPlace/introspectionSchema.json":
            return Promise.resolve(importedModule$0);
        default:
            return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
    }
};
const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
    cwd: baseDir,
    importFn,
    fileType: "json",
}), {
    readonly: true,
    validate: false
});
export const rawServeConfig = undefined;
export async function getMeshOptions() {
    const pubsub = new PubSub();
    const sourcesStore = rootStore.child('sources');
    const logger = new DefaultLogger("GraphClient");
    const cache = new MeshCache({
        ...{},
        importFn,
        store: rootStore.child('cache'),
        pubsub,
        logger,
    });
    const sources = [];
    const transforms = [];
    const additionalEnvelopPlugins = [];
    const marketPlaceTransforms = [];
    const additionalTypeDefs = [];
    const marketPlaceHandler = new GraphqlHandler({
        name: "MarketPlace",
        config: { "endpoint": "http://localhost:8000/subgraphs/name/ludocoin/ludocoin-subgraph" },
        baseDir,
        cache,
        pubsub,
        store: sourcesStore.child("MarketPlace"),
        logger: logger.child("MarketPlace"),
        importFn,
    });
    sources[0] = {
        name: 'MarketPlace',
        handler: marketPlaceHandler,
        transforms: marketPlaceTransforms
    };
    additionalEnvelopPlugins[0] = await UsePollingLive({
        ...({
            "defaultInterval": 1000
        }),
        logger: logger.child("pollingLive"),
        cache,
        pubsub,
        baseDir,
        importFn,
    });
    const additionalResolvers = [];
    const merger = new BareMerger({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
    });
    return {
        sources,
        transforms,
        additionalTypeDefs,
        additionalResolvers,
        cache,
        pubsub,
        merger,
        logger,
        additionalEnvelopPlugins,
        get documents() {
            return [
                {
                    document: LatestPmPoolsDocument,
                    get rawSDL() {
                        return printWithCache(LatestPmPoolsDocument);
                    },
                    location: 'LatestPmPoolsDocument.graphql'
                }, {
                    document: LatestPoolsDocument,
                    get rawSDL() {
                        return printWithCache(LatestPoolsDocument);
                    },
                    location: 'LatestPoolsDocument.graphql'
                }, {
                    document: PopularPoolsDocument,
                    get rawSDL() {
                        return printWithCache(PopularPoolsDocument);
                    },
                    location: 'PopularPoolsDocument.graphql'
                }, {
                    document: RankedPoolsDocument,
                    get rawSDL() {
                        return printWithCache(RankedPoolsDocument);
                    },
                    location: 'RankedPoolsDocument.graphql'
                }, {
                    document: SinglePoolDocument,
                    get rawSDL() {
                        return printWithCache(SinglePoolDocument);
                    },
                    location: 'SinglePoolDocument.graphql'
                }, {
                    document: SinglePositionDocument,
                    get rawSDL() {
                        return printWithCache(SinglePositionDocument);
                    },
                    location: 'SinglePositionDocument.graphql'
                }, {
                    document: TaggedPoolsDocument,
                    get rawSDL() {
                        return printWithCache(TaggedPoolsDocument);
                    },
                    location: 'TaggedPoolsDocument.graphql'
                }, {
                    document: TopPlayerTagDocument,
                    get rawSDL() {
                        return printWithCache(TopPlayerTagDocument);
                    },
                    location: 'TopPlayerTagDocument.graphql'
                }, {
                    document: TopTagsDocument,
                    get rawSDL() {
                        return printWithCache(TopTagsDocument);
                    },
                    location: 'TopTagsDocument.graphql'
                }, {
                    document: TopTagsCatDocument,
                    get rawSDL() {
                        return printWithCache(TopTagsCatDocument);
                    },
                    location: 'TopTagsCatDocument.graphql'
                }, {
                    document: UserDataDocument,
                    get rawSDL() {
                        return printWithCache(UserDataDocument);
                    },
                    location: 'UserDataDocument.graphql'
                }, {
                    document: UserPositionsDocument,
                    get rawSDL() {
                        return printWithCache(UserPositionsDocument);
                    },
                    location: 'UserPositionsDocument.graphql'
                }
            ];
        },
        fetchFn,
    };
}
export function createBuiltMeshHTTPHandler() {
    return createMeshHTTPHandler({
        baseDir,
        getBuiltMesh: getBuiltGraphClient,
        rawServeConfig: undefined,
    });
}
let meshInstance$;
export function getBuiltGraphClient() {
    if (meshInstance$ == null) {
        meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
            const id = mesh.pubsub.subscribe('destroy', () => {
                meshInstance$ = undefined;
                mesh.pubsub.unsubscribe(id);
            });
            return mesh;
        });
    }
    return meshInstance$;
}
export const execute = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));
export const subscribe = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK(globalContext) {
    const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
    return getSdk((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export const LatestPmPoolsDocument = gql `
    query LatestPmPools($creator: Bytes!) {
  pools(
    orderBy: start_time_unix
    orderDirection: desc
    where: {creator_: {id: $creator}}
    first: 5
  ) {
    id
  }
}
    `;
export const LatestPoolsDocument = gql `
    query LatestPools @live(interval: 1000) {
  pools(orderBy: start_time_unix, orderDirection: desc, where: {state: 0}) {
    id
  }
}
    `;
export const PopularPoolsDocument = gql `
    query PopularPools @live(interval: 1000) {
  pools(orderBy: pop, orderDirection: desc, where: {state: 0}) {
    id
  }
}
    `;
export const RankedPoolsDocument = gql `
    query RankedPools @live(interval: 1000) {
  pools(orderBy: rank, orderDirection: desc, where: {state: 0}) {
    id
  }
}
    `;
export const SinglePoolDocument = gql `
    query SinglePool($id: ID!) @live {
  pool(id: $id) {
    creator {
      id
    }
    deadline_unix
    description
    stake_value_wei
    start_time_unix
    topic
    outcome
    tags(orderBy: volume, orderDirection: desc) {
      name
    }
    volume
    weights
    img_url
    color
    state
    cat {
      name
    }
    positions(first: 5, orderBy: timestamp, orderDirection: desc) {
      holder {
        id
      }
      option
      size
      timestamp
    }
  }
}
    `;
export const SinglePositionDocument = gql `
    query SinglePosition($id: ID!) {
  position(id: $id) {
    pool {
      id
      img_url
      topic
      color
    }
    option
    size
  }
}
    `;
export const TaggedPoolsDocument = gql `
    query TaggedPools($tags: [String!]!) @live {
  pools(where: {tags_contains_nocase: $tags}) {
    id
  }
}
    `;
export const TopPlayerTagDocument = gql `
    query TopPlayerTag($player: String) @live {
  playerTags(
    orderBy: volume
    orderDirection: desc
    where: {player: $player}
    first: 4
  ) {
    tag {
      name
    }
  }
}
    `;
export const TopTagsDocument = gql `
    query TopTags($name: String) {
  tags(first: 5, orderBy: volume, orderDirection: desc) {
    name
  }
}
    `;
export const TopTagsCatDocument = gql `
    query TopTagsCat($name: String) {
  tags(
    first: 5
    orderBy: volume
    orderDirection: desc
    where: {cat_: {name: $name}}
  ) {
    name
  }
}
    `;
export const UserDataDocument = gql `
    query UserData($player: ID!) @live {
  player(id: $player) {
    created
    dateJoined
    lastSeen
    played
    profit
    staked
  }
}
    `;
export const UserPositionsDocument = gql `
    query UserPositions($holder: Bytes) @live {
  positions(
    first: 5
    orderBy: timestamp
    orderDirection: desc
    where: {holder_: {id: $holder}}
  ) {
    id
  }
}
    `;
export function getSdk(requester) {
    return {
        LatestPmPools(variables, options) {
            return requester(LatestPmPoolsDocument, variables, options);
        },
        LatestPools(variables, options) {
            return requester(LatestPoolsDocument, variables, options);
        },
        PopularPools(variables, options) {
            return requester(PopularPoolsDocument, variables, options);
        },
        RankedPools(variables, options) {
            return requester(RankedPoolsDocument, variables, options);
        },
        SinglePool(variables, options) {
            return requester(SinglePoolDocument, variables, options);
        },
        SinglePosition(variables, options) {
            return requester(SinglePositionDocument, variables, options);
        },
        TaggedPools(variables, options) {
            return requester(TaggedPoolsDocument, variables, options);
        },
        TopPlayerTag(variables, options) {
            return requester(TopPlayerTagDocument, variables, options);
        },
        TopTags(variables, options) {
            return requester(TopTagsDocument, variables, options);
        },
        TopTagsCat(variables, options) {
            return requester(TopTagsCatDocument, variables, options);
        },
        UserData(variables, options) {
            return requester(UserDataDocument, variables, options);
        },
        UserPositions(variables, options) {
            return requester(UserPositionsDocument, variables, options);
        }
    };
}
