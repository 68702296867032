export default function currency(value) {

    if( Math.abs(Number(value)) <= 1.0e-3) return "0";

    return Math.abs(Number(value)) >= 1.0e+9

    ? (Math.abs(Number(value)) / 1.0e+9).toFixed(0) + "B"
    : Math.abs(Number(value)) >= 1.0e+6

    ? (Math.abs(Number(value)) / 1.0e+6).toFixed(0) + "M"
    : Math.abs(Number(value)) >= 1.0e+3
    ? (Math.abs(Number(value)) / 1.0e+3).toFixed(0) + "K"
    : Math.abs(Number(value));
}
