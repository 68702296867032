import { Box, IconButton, Typography } from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import {Link} from 'react-router-dom'
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';

import MarketPlaceABI from '../chain/json/MarketPlace.abi.json'
import addresses from '../chain/json/addresses.json'
import { ColoredBetLogo } from '../cosmetic/ColoredBetLogo';
import { BetBoxStatic, BetBox, AnimatedIcon } from '../../Style'
import {useWeb3React} from '@web3-react/core';
import {trunc} from "../../utils/string_utils";
import {SinglePoolDocument, SinglePositionDocument, UserPositionsDocument} from '../../.graphclient';
import {useQuery} from 'urql';
import usePool from '../chain/hooks/usePool';
import LudoAmount from '../chain/LudoAmount';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CasinoIcon from '@mui/icons-material/Casino';
import {StateLogo} from '../chain/StateLogo';
import {CatLogo} from '../CatLogo';
import usePoolState from '../chain/pool/usePoolState';
import {TextBoxGlass} from '../TextBoxGlass';

export function BetSplash({id}) {
    const { data, fetching, error } = usePool(id)
    const state = usePoolState(id);

    if(!fetching && !error && state != null) {
        return( <> 
            <Link style={{ textDecoration: 'none' }} to={"/pool/" + id}>
            <TextBoxGlass style={{ textDecoration: 'none' }} transparency="77" radius={30}>
                <Box style={{ flex: '1 0 25%'}}>
                    <div style={{ display : 'flex', justifyContent: 'space-between' }}>
                        <ColoredBetLogo img_url={data.pool.img_url} color_id={data.pool.color}/>
                        <CatLogo text={ data.pool.cat }/>
                    </div>
                    <div style={{ fontSize: 26, marginLeft: 'auto', textAlign : 'center', marginTop: 10, color: 'white'}}> 
                        {trunc(data.pool.topic,75,true)}
                    </div>
                </Box>
                <div variant='h6' style={{ fontSize: 18, maxWidth: '15em', textAlign : 'left', marginTop : 10, color: 'white'}}> 
                    {trunc(data.pool.description,125, true)}
                </div>
                <Box style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div variant='h6' style={{ fontSize: 18, color: '#999', textAlign : 'left', display: 'flex', justifyItems: 'center', alignItems: 'center', marginTop : 10}}> 
                        Volume : &nbsp; {data.pool.volume !== undefined ? <LudoAmount size={18} amount={parseFloat(data.pool.volume[0]) + parseFloat(data.pool.volume[1]) + parseFloat(data.pool.stake_value_wei)}/> : "?"}
                    </div>
                    <StateLogo state={state} placement="left" style={{ color: 'white', marginLeft: 'auto', padding: 0 }}/>
                </Box>
            </TextBoxGlass>
            </Link>
        </>)
    } else {
        return (
        <Link style={{ textDecoration: 'none' }} to={"/pool/" + id}>
            <TextBoxGlass style={{ textDecoration: 'none' }} transparency="77" radius={30}>
                <Box style={{ flex: '1 0 25%'}}>
                    <ColoredBetLogo img_url=""/>
                    <Typography variant='h5' style={{ marginLeft: 'auto', textAlign : 'center', marginTop: 5, color: 'white'}}> 
                        ...
                    </Typography>
                </Box>
                <Typography variant='h6' style={{ maxWidth: '15em', textAlign : 'left', marginTop : 10, color: 'white'}}> 
                    ...
                </Typography>
                <Box style={{ display: 'flex'}}>
                    <Typography variant='h6' style={{ color: '#999', textAlign : 'left', marginLeft: 10, marginTop : 10}}> 
                        Volume : 0 Ł
                    </Typography>
                    <IconButton onClick={(e) => e.stopPropagation() } style={{ color: '#fff', marginLeft: 'auto'}}>
                        <LayersIcon/>
                    </IconButton>
                </Box>
            </TextBoxGlass>
            </Link>
        )
    }
}

export function PositionSplash({id}) {
    const [result] = useQuery({
        query: SinglePositionDocument,
        variables : { id : id }
    })

    const { data, fetching, error } = result

    
    if(!fetching) {
            
        const poolData = data.position.pool;
        return(
            <> 
            <Link style={{ textDecoration: 'none', color: 'white' }} to={"/pool/" + poolData.id}>
            <TextBoxGlass style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyItems: 'center', padding: 10, textDecoration: 'none'}} padding={20} transparency="ee">
                    <div style={{display: 'flex', textDecoration: 'none', marginTop: 10, alignItems: 'center'}}>
                    <ColoredBetLogo height={70} width={70} img_url={poolData.img_url} color_id={poolData.color}/>
                        <div variant='h5' style={{ fontSize: 26, marginLeft: 10, textAlign : 'center'}}> 
                            { trunc(poolData.topic, 25, true) }
                        </div>
                        <div variant='h6' style={{ fontSize: 18, color: '#999', marginLeft: 40}}> 
                            Play { <LudoAmount amount={data.position.size}/> }
                        </div>
                        <div variant='h6' style={{ fontSize : 18, color: '#999', marginLeft: 30}}> 
                            Option { data.position.option ? "Yes" : "No" }
                        </div>
                    </div>
            </TextBoxGlass>
            </Link>
        </>   
        )
    } else {
        return null;
    }
}

export function BetSplashSmall({id}) {
    const [result] = useQuery({
        query: SinglePoolDocument,
        variables : { id : id }
    })

    const { data, fetching, error } = result

    if(!fetching) {

        const poolData = data.pool;

        return(
            <> 
            <Link style={{ textDecoration: 'none', color: 'white' }} to={"/pool/" + id}>
            <TextBoxGlass style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyItems: 'center', padding: 10, textDecoration: 'none'}} padding={20} transparency="ee">
                    <div style={{display: 'flex', marginTop: 10, alignItems: 'center'}}>
                    <ColoredBetLogo height={70} width={70} img_url={poolData.img_url} color_id={poolData.color}/>
                        <Typography variant='h5' style={{ marginLeft: 10, textAlign : 'center'}}> 
                            { trunc(poolData.topic, 20, true) }
                        </Typography>
                        <Typography variant='h6' style={{ color: '#999', marginLeft: 40, display: 'flex'}}> 
                           Vol: &nbsp; {data.pool.volume !== undefined ? <LudoAmount size={18} amount={parseFloat(data.pool.volume[0]) + parseFloat(data.pool.volume[1]) + parseFloat(data.pool.stake_value_wei)}/> : "?"}
                        </Typography>
                    </div>
            </TextBoxGlass>
            </Link>
        </>)
    } else {
        return null;    
    }
}
