import {useWeb3React} from '@web3-react/core';
import { useEffect, useCallback } from 'react'
import { injected } from './connectors';

function WalletProvider({ children }) {

    const { activate, active, account } = useWeb3React();

    const updateWalletState = useCallback( () => {
        if (localStorage.getItem('connected') === "1") {
            activate(injected);
        }
        if (account) {
            localStorage.setItem('connected', 1);
        } else {
            localStorage.setItem('connected', 0);
            //disconnect?
        }
    } ,[account]);

    
    useEffect(() =>{
        if(window.ethereum) {
            updateWalletState();
        } else {
            window.addEventListener('ethereum#initialized', updateWalletState, {
                once: true,
            });
            setTimeout(updateWalletState, 3000);
        }
    }, [updateWalletState]);


    
    return children
}

export default WalletProvider

