import {Button, ButtonGroup} from '@material-ui/core'
import {Clear} from '@material-ui/icons'
import {useTagStore} from '../../stores/TagStore'
import {BetBox} from '../../Style';
import FadeDiv from '../FadeDiv';

export default function Tag(props) {
    const addTag = useTagStore((state) => state.addTag)
    const removeTag = useTagStore((state) => state.removeTag)
    const tags = useTagStore((state) => state.tags)
    const added = tags.includes(props.text);

    const opacity = added ? "55" : "ff";

    return(
        <div {...props}>
            <FadeDiv duration={500}>
            <BetBox style={{ padding: 0 }}>
            <Button style={{
                background: 'linear-gradient( #111111' +opacity + ', #111) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                backgroundColor: '#44444444',
                color: 'white',
                borderRadius: 50,
                backdropFilter: 'drop-shadow(7px, 7px, 7px #000)'
            }} endIcon={added && <Clear/>} onClick={() => { if(added) { removeTag(props.text) } else { addTag(props.text)}}}  variant='contained'>
            {props.text}
        </Button>
        </BetBox>
        </FadeDiv>
        </div>
    )
}
