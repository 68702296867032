import CasinoIcon from '@mui/icons-material/Casino';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import MemoryIcon from '@mui/icons-material/Memory';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {darkSec, prim, svgGradient} from '../Style';

export function CatLogo(props) {
    const catMap = {
        "other" : <CasinoIcon style={svgGradient}/>,
        "sport" : <SportsFootballIcon style={svgGradient}/>,
        "politics" : <HowToVoteIcon style={svgGradient}/>,
        "tech" : <MemoryIcon style={svgGradient}/>,
        "crypto" : <CurrencyBitcoinIcon style={svgGradient}/>,
        "economy" : <AccountBalanceIcon style={svgGradient}/>
    }

    return( <div style={props.style}> {catMap[props.text.name]} </div> )
}
