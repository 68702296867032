import {Button, ButtonGroup} from '@material-ui/core'
import {Clear} from '@material-ui/icons'
import {useCatStore} from '../../stores/CatStore'
import CasinoIcon from '@mui/icons-material/Casino';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import MemoryIcon from '@mui/icons-material/Memory';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import FadeDiv from '../FadeDiv';
import {BetBox} from '../../Style';
import {useTagStore} from '../../stores/TagStore';

export default function NewCat(props) {
    const updateCat = useCatStore((state) => state.updateCat)
    const clearCat = useCatStore((state) => state.clearCat)
    const cat = useCatStore((state) => state.cat)
    const added = cat.toLowerCase() === props.text;
    const clearTags = useTagStore((state) => state.clearTags)

    const catMap = {
        "" : <ArrowDropDownCircleIcon/>,
        "other" : <CasinoIcon/>,
        "sport" : <SportsFootballIcon/>,
        "politics" : <HowToVoteIcon/>,
        "tech" : <MemoryIcon/>,
        "crypto" : <CurrencyBitcoinIcon/>,
        "economy" : <AccountBalanceIcon/>
    }

    console.log(cat)

    const opacity = added ? "55" : "ee";

    return(
        <div {...props}>
            <FadeDiv  duration={500}>
            <Button style={{
                background: 'linear-gradient( #111111' + opacity +  ', #111) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
                border: '1.2px solid transparent',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                backgroundColor: '#44444444',
                color: 'white',
                borderRadius: 50,
                backdropFilter: 'drop-shadow(7px, 7px, 7px #000)',
                transition: 'background 2s ease-in-out, trasform 0.3s ease-in-out',
                fontSize: 18,
                "&:hover" : {
                    filter: 'drop-shadow(7px 7px 12px #7700b3)',
                }
            }} endIcon={<div style={{ display: 'flex', marginTop: -2, alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }}>  {catMap[props.text.toLowerCase()]} </div>} onClick={() => {updateCat(props.text.toLowerCase()); clearTags() }}  variant='contained'>
            {props.text != "" ? props.text : "Category"}
        </Button>
        </FadeDiv>
        </div>
    )
}
