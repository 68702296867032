import usePool from "../hooks/usePool";
import {useWeb3React} from "@web3-react/core";
import {useEffect, useState} from "react";
import {useBlockTime} from "./useBlockTime";
import { useTime } from "react-time-sync";

export default function usePoolState(poolId) {
    const { data, fetching, error } = usePool(poolId);
    const { library, active, error : err } = useWeb3React()
    const time = useTime()

    if(!fetching && !error) {
        const {state, deadline_unix} = data.pool;


        if(state == 0 && time < deadline_unix) {
            return 0;
        }

        if(state == 1 || (time >= deadline_unix && state == 0)) {
            return 1;
        }
        //add time
        if(state == 2) {
            return 2;
        }
    } else {
        return null;
    }
}
