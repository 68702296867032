

import { useState, useEffect, cloneElement } from 'react';
import { Toolbar, SwipeableDrawer, Button, Box, IconButton, Typography, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import Token from '@mui/icons-material/Token';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import CasinoIcon from '@mui/icons-material/Casino';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MapIcon from '@material-ui/icons/Map'
import {Link, useLocation} from 'react-router-dom'
import { useWeb3React } from '@web3-react/core';
import { EtherSWRConfig } from 'ether-swr';
import {injected} from '../../web3/connectors'

import { EthBalance, TokenBalance, ABIs, PoolModal, PlayerWinnings, PmPoolList, GetPmPool } from '../chain/Balance'
import SimpleModal from '../container/SimpleModal'
import { StyledLink, drawerPaperTheme, BaseBar, StyledLogo, StyledButton, LightButton, AnimatedIcon, prim, sec, iconStyle} from '../../Style'
import { trunc } from '../../utils/string_utils'
import { isMobile } from 'react-device-detect';

import logo from '../../media/logo.webp';
import {SearchBox} from './SearchBox';
import {ChronoBtn, PopBtn, TopBtn} from '../btn/OrderBtn';
import Tag from '../btn/Tag';
import Cat from '../btn/Cat';
import {useCatStore} from '../../stores/CatStore';
import TollIcon from '@mui/icons-material/Toll';
import NewCat from '../btn/NewCat';
import {CustomTooltip} from '../CustomTooltip';
import {useWidth} from '../../utils/dimUtils';
import {TextBoxGlass} from '../TextBoxGlass';
import CategorySelect from './CategorySelect';

export function TopBar() {

    const { account, active, activate, deactivate, library, error, chainId } = useWeb3React();
    const location = useLocation();

    const inApp = location.pathname.substring(0,5) === '/dapp' || location.pathname.substring(0,5) === '/pool' || location.pathname.substring(0,5) === '/user';
    const inMainPage = location.pathname === '/dapp'
    let iconStyle = { height : window.innerHeight / 30, width: window.innerHeight / 30};
    
    const cat = useCatStore((state) => state.cat)
    const allCats = useCatStore((state) => state.allCats)
    const width = useWidth();

    let pages = [];
    let icons = [];
    if (!inApp) {
        pages = [ 'Roadmap' ];
        //pages = [ 'Litepaper', 'Dapp', 'Token', 'Roadmap'];
        icons = [ <HistoryEdu style={iconStyle}/>, <CasinoIcon style={iconStyle}/>, <Token style={iconStyle}/>, <MapIcon style={iconStyle}/>]
    } else {
        pages = [ ];
    }


    const [scrollPosition, setScrollPosition] = useState(0);
    const [anchorElNav, setAnchorElNav] = useState(false);
    const [isOpen, setIsOpen] = useState(0);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
        setIsOpen(1);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(false);
        setIsOpen(0);
    };

    useEffect( () => {
        handleCloseNavMenu();
    }, [location]);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function connectWallet() {
        if (localStorage.getItem('connected') === "0") {
            activate(injected);
        }
    }

    function disconnect() {
        localStorage.setItem("connected", 0);
        deactivate();
    }

    const theme = drawerPaperTheme();

    return (
        <BaseBar height={window.innerHeight} color="transparent" id="appbar" scroll={scrollPosition}>
            <div style={{marginTop: isMobile ? 8 : 0, display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                 <EtherSWRConfig
                    value={{
                        web3Provider: library,
                        ABIs: new Map(ABIs()),
                        refreshInterval: 10000
                    }}>

                <div style={{ display: 'flex', marginLeft: 15 }}>
                    <StyledLink style={{ marginTop: 8 }} to={ inApp ? "/dapp" : "/home" }>
                        <StyledLogo alt="logo" src={logo}/>
                    </StyledLink>

                    
                   

                    </div>
                    { inApp &&  inMainPage &&
                            <div style={{display: 'flex', marginRight: 50, marginLeft: 'auto'}}>
                            <ChronoBtn/>
                            <PopBtn/>
                            <TopBtn/>
                            </div>
                    }
                    { inApp &&  inMainPage &&
                            <SearchBox/>
                    }

                    <CategorySelect/>

                    { !inApp && 
                    <StyledLink style={{ textDecoration: 'none' }} to="/dapp">
                        <StyledButton variant="outlined">
                            Enter dApp
                        </StyledButton>
                    </StyledLink>
                    }

                { isMobile &&
                <Box style={{ flexGrow: 1 }}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                    </IconButton>

                    <div className={theme.root}>
                    <SwipeableDrawer 
                        id="menu-drawer"
                        anchor='top'
                        variant='temporary'
                        keepMounted
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        onOpen={handleOpenNavMenu}
                        color='primary'
              
                        style={{
                            display: { xs: 'flex', md: 'none' },
                            flexDirection: 'column',
                            backgroundColor: '#333',
                            opacity: '0.9',
                            height: '100%',
                        }}
                    >
                        <div style={{marginBottom: window.innerHeight / 3}}/>
                            {pages.map((page, index) => (
                                <MenuItem component={StyledLink} to={page.toLowerCase()} key={page} style={{ backgroundColor: '#222', color: 'white'}} onClick={handleCloseNavMenu}>
                                    <Button startIcon={icons[index]} style={{color: 'white', fontSize: '7vw', marginLeft: 'auto', marginRight: 'auto' }}>{page.toUpperCase()}</Button>
                                </MenuItem>
                            ))}
                        <div style={{marginBottom: window.innerHeight / 3.5}}/>
                    </SwipeableDrawer>
                    </div>
                </Box>
                }
                
                { !inApp &&
                <Box sx={{ flexDirection: 'row-reverse', flexGrow: 1, marginRight: 15, display: { xs: 'none', md: 'flex' }, alignContent: 'center' }}>
                    {pages.map((page) => (
                    <StyledLink key={page} to={page.toLowerCase()} style={{ display: 'flex' }}>
                        <LightButton
                            style={{ color : 'white', marginLeft: '30px', fontSize: 16}}
                            key={page}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                        {page}
                        </LightButton>
                    </StyledLink>
                    ))}
                </Box>
                }


                    

                                    <div style={{ justifySelf: 'flex-start', display: 'flex', justifyContent: 'flex-end' }}>

                    { inApp && !active &&
                        <StyledButton onClick={connectWallet} style={{ marginRight: 15 }} mycolor='#7700b3' variant="outlined">
                            Connect wallet
                        </StyledButton>
                    }

                    { inApp && active && !error && !isMobile &&
                        <>
                        <StyledButton style={{  marginRight: 15 }} startIcon={<TollIcon/>} mycolor={prim} variant="outlined">

                        <div className='mono' style={{ marginRight: 10}}>
                            <TokenBalance/> Ł
                        </div>
                        </StyledButton>

                        <Link style={{ textDecoration: 'none' }} to={"/user/" + account}>
                            <StyledButton style={{  marginRight: 15 }} startIcon={<AccountBalanceWalletIcon/>} mycolor={sec} variant="outlined">

                                <div className='mono' style={{ letterSpacing: 0, display: 'flex' }}>
                                    {trunc(account, isMobile ? 7 : 10, true) } 
                                </div> 
                            </StyledButton>
                        </Link>
                        </>
                    }
                    </div>

                </EtherSWRConfig>

                
                { width < 1000 && false &&
                <Box sx={{ marginLeft : 10, flexGrow: 1, alignItems: 'right'}}>
                    <IconButton
                        aria-label="dropdown menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={isOpen ? handleCloseNavMenu : handleOpenNavMenu}
                        color="inherit"
                        style={{ marginLeft: 'auto'}}
                    >
                        <AnimatedIcon show={isOpen} style={{ marginLeft: 'auto', fill: "url(#gradient)"}}/>
                                            </IconButton>
                </Box>
                }
            </div>
            <svg width="0" height="0">
                            <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                                <stop stopColor={sec} offset="0%" />
                                <stop stopColor={prim} offset="100%" />
                            </linearGradient>
                        </svg>

            
        </BaseBar>
    )
}
