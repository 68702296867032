import currency from '../../utils/currency'
import { formatEther } from 'ethers/lib/utils'

export default function LudoAmount(props) {
    return(
        <div {...props}>
        <div style={{ display: 'flex' }}>
        <div style={{ fontSize: props.size, marginRight: props.marginRight }}>
            <strong>
                { currency(parseFloat(formatEther(props.amount.toString()))) }
            </strong>
        </div>
        <div style={{ color : props.colorSec, fontSize: props.size / 1.5, marginLeft : 0, verticalAlign: 'bottom', display: 'table-cell' }}>
            Ł
        </div>
        </div>
        </div>
    )
}

LudoAmount.defaultProps = {
    marginRight: 5,
    colorSec : '',
    size: 26
}
