import React from 'react';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { useCatStore } from '../../stores/CatStore';
import NewCat from '../btn/NewCat';
import { isMobile } from 'react-device-detect';

const CategorySelect = () => {

    const inApp = location.pathname.substring(0,5) === '/dapp' || location.pathname.substring(0,5) === '/pool' || location.pathname.substring(0,5) === '/user';
    const inMainPage = location.pathname === '/dapp'

    const cat = useCatStore((state) => state.cat);
    const allCats = useCatStore((state) => state.allCats);

    if (!inApp || isMobile || !inMainPage) {
        return null;
    }

    return (
        <FormControl style={{
            m: 1,
            minWidth: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginLeft: 50,
            marginRight: 50,
        }} hiddenLabel>
            <Select
                labelId="category-select"
              value={cat}
              defaultValue=""
              displayEmpty={true}
              autoWidth
              id="cats"
              inputProps={{ IconComponent: () => null, sx: { padding: '0px !important' } }}
                MenuProps={{ sx : { backgroundColor: '#22222200', backdropFilter: 'blur(1px)'  }  }}
                  SelectDisplayProps={{ style : { padding: 0, paddingBottom : 1, "&:before" : { borderWidth: 0 } } }}
                  style={{ zIndex: 100, display: 'flex', justifyContent: 'center', alignContent: 'center', padding: '0px !important', borderWidth: 0 }}
                  sx = {{
                       "& .MuiPaper-root": {
                        backgroundColor: 'white',
                        },
                    }}
            >
                {allCats.map((category) => (
                    <MenuItem key={category} value={category} style={{ opacity: 1 }}>
                        <NewCat text={category} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CategorySelect;
