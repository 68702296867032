import {BetBox} from "../Style";
import {useSpring,animated, config} from "@react-spring/web";
import {useNotifStore} from "../stores/NotifStore";
import {TextBoxGlass} from "./TextBoxGlass";

export default function Notif(props) {
    const removeNotif = useNotifStore((state) => state.removeNotif)
    const bottom = 50 + 100*props.index;
    const to = props.time != 4001 ? [{opacity: 1 , position : 'fixed', bottom: bottom, right: 40, zIndex: 100, fontSize: 22}, {opacity: 0 , position : 'fixed', bottom: bottom, right: 40, zIndex: 100, fontSize: 22}] : [{opacity: 1 , position : 'fixed', bottom: bottom, right: 40, zIndex: 100, fontSize: 22}]

    
    const style2 = useSpring({ config: {duration: props.time}, from : { opacity: 0.1, position : 'fixed', bottom: bottom, right: 20, zIndex: 100, fontSize: 22 }, to : to,
    onRest : () => { if(props.time != 4001) removeNotif(props.text) }    });

    return(
        <animated.div style={{...style2, maxWidth: 'max(20%, 320px)', cursor: 'pointer'}} onClick={ props.time != 4001 ? {} : () => {props.action.call(); removeNotif(props.text)}}>
            <TextBoxGlass padding={25} transparency='22' blur="5" style={{ padding: 15, filter: 'drop-shadow(0,0,5px,#fff)' }}>
                <div className="mono" style={{ display: 'flex', alignContent: 'center' }}>
                    {props.icon}
                    <div style={{ marginLeft: 10, fontSize: 20 }}>
                        {props.text} 
                    </div>
                </div>
            </TextBoxGlass>
        </animated.div>
    )
}

Notif.defaultProps = {
    action : () => {},
    time : 6000,
    index : 0
}
