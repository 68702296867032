import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CasinoIcon from '@mui/icons-material/Casino';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {IconButton} from '@mui/material';
import {CustomTooltip} from '../CustomTooltip';

export function StateLogo(props) {

    const {state, size} = props;

    const labels = [ "Ongoing", "Voting", "Settled" ]

    return(
        <CustomTooltip placement={props.placement} text={labels[state]}>
        <IconButton onClick={(e) => e.stopPropagation() } style={props.style}>
            { state === 0 &&
            <CasinoIcon style={{ width: size, height: size }}/>
            }
            { state === 1 &&
            <HowToVoteIcon style={{ width: size, height: size }}/>
            }
            { state === 2 &&
            <CheckCircleOutlineIcon style={{ width: size, height: size }}/>
            }
        </IconButton>
        </CustomTooltip>
    )
}

StateLogo.defaultProps = {
    color : "#fff",
    size : 20,
    placement : "right"
}
