import { styled, createTheme, makeStyles } from "@material-ui/core/styles"
import { Typography, AppBar, lighten, darken, Box, Menu, IconButton, TextField, Tabs} from '@material-ui/core';
import { colorInterpolator, rgbToHex, hexToRgb, colorGradient } from "./utils/color_utils";
import MenuIcon from '@material-ui/icons/Menu'
import {Img} from 'react-image'
import { Button } from "@material-ui/core"
import {Link} from 'react-router-dom'
import {isMobile} from "react-device-detect";
import { DateTimePicker } from "@mui/x-date-pickers";
import {LinearProgress, linearProgressClasses, Tab, ToggleButton} from "@mui/material";


export const prim = "#7700b3";
export const sec = "#b38600";
export const lightPrim =  lighten("#7700b3", 0.15);
export const lightSec = lighten("#b38600", 0.15);
export const lighterSec = lighten("#b38600", 0.17);
export const darkPrim =  darken("#7700b3", 0.15);
export const darkerPrim =  darken("#7700b3", 0.60);
export const darkSec = darken("#b38601", 0.15);
export const darkerSec = darken("#b38600", 0.60);
export const defGradient = 'linear-gradient(to right, #7700b3, #b38600)'
export const borderGradient = 'linear-gradient( #7700b3, #b38600), linear-gradient(to right, #7700b3, #b38600)';
export const svgGradient = { color : darkSec, fill: "url(#gradient)" }

export const iconStyle = { height : window.innerHeight / 30, width: window.innerHeight / 30};

export const gradText = {
          textDecoration: 'none',
          background: defGradient,
          webkitTextFillColor : "transparent",
            backgroundClip: 'text',
        webkitBackgroundClip: 'text',
          color: 'transparent',
        }


export const AnimatedIcon = styled(MenuIcon) ((props) => ({
    transform: props.show ? 'rotate(90deg)' : '', 
    transition: 'transform 200ms ease',
}));

export const ModalBox = styled(Box)(({width = '30%'}) => ({
    background: 'linear-gradient( #111, #111) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
    border: '3px solid transparent',
    position: 'absolute',
    top: '42%',
    left: isMobile ? '47.5%' : '50%',
    marginLeft: 30,
    marginRight: 30,
    padding: 35,
    borderRadius: 20,
    transform: 'translate(-55%, -50%)',
    width: width,
    backgroundColor : '#222',
    color: 'white',
    boxShadow: 24,
    p: 4,
}));

export const PoolCreationBox = styled(Box)({
    background: 'linear-gradient( #111, #111) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
    border: '3px solid transparent',
    position: 'absolute',
    top: '42%',
    left: isMobile ? '47.5%' : '50%',
    marginLeft: 30,
    marginRight: 30,
    padding: 35,
    borderRadius: 20,
    transform: 'translate(-55%, -50%)',
    width: '60vw',
    backgroundColor : '#222',
    color: 'white',
    boxShadow: 24,
    p: 4,
});


export const BetBox = styled(Box)({
    padding: 30,
    background: 'linear-gradient( #101010de, #101010) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
    border: '2px solid transparent',
    borderRadius: 20,
    color: 'white',
    boxShadow: 24,
    p: 4,
    filter: 'drop-shadow(7px 7px 7px #111)',
    transition: 'filter 0.4s, transform 0.3s ease-in-out',
    "&:hover" : {
        transform: 'scale(1.03) rotate(0.01deg)',
        filter: 'drop-shadow(7px 7px 7px #000)',
    }

});

export const BetBoxStatic = styled(Box)({
    padding: 30,
    background: 'linear-gradient( #111, #111) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
    border: '2px solid transparent',
    borderRadius: 20,
    color: 'white',
    boxShadow: 24,
    p: 4,
    filter: 'drop-shadow(7px 7px 7px #111)',
    transition: 'filter 0.4s, transform 0.3s ease-in-out',
});


export const LudoLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: sec
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: prim,
    filter: 'drop-shadow(5px, 5px, 5px, #fff)'
  },
}));


export const CircleRoad = styled(Box) ((props) => ({
    backgroundColor: props.active ? lighterSec : darkSec,
    borderRadius: 100,
    height: '4vh',
    width: '4vh',
    marginBottom: 'calc(-100px - 1.5vh)',
    marginLeft: '-1.5vh',
    zIndex: 100,
    marginTop: 50,
    transition: 'background-color 1s, height 0.3s ease-in-out, width 0.3s ease-in-out, margin-bottom 0.3s ease-in-out, margin-left 0.3s ease-in-out',
    '&:focus': {
        height: '4.5vh', 
        width: '4.5vh',
        marginBottom: 'calc(-100px - 2vh)',
        marginLeft: '-1.75vh',
        backgroundColor: lightSec

    },
    '&:hover, &:active' : { 
        height: '4.5vh', 
        width: '4.5vh',
        marginBottom: 'calc(-100px - 2vh)',
        marginLeft: '-1.75vh',
        backgroundColor: sec
    }
}))

export const GradientButton = styled(Button)({
    padding: 15,
    background: 'linear-gradient( #111, #111) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
    border: '2px solid transparent',
    color: 'white',
    p: 4,
    transition: 'filter 0.4s, transform 0.3s ease-in-out 0.3s background ease-in-out',
    '&:focus': {
        backgroundColor : "#111",
    },
    '&:hover, &:active' : {
        backgroundColor: '#222',
    },
});



export const BaseBar = styled(AppBar) ((props) => ({
        flexGrow: 1,
        minHeight: 80,
        opacity: 0.94,
        textAlign: 'center',
        background: 'linear-gradient(90deg, rgba(6,0,9,0.5) 0%, rgba(9,9,9,0.5) 50%, rgba(6,0,9,0.5) 100%)',
        backdropFilter: 'blur(4px)',
        borderBottom: props.scroll > 20 ? '2px groove black' : '0px groove black',
        borderBottomColor: colorGradient(( 2 * Math.pow(Math.sin(props.scroll / ( 500 - props.height / 10) - 0.05), 4)) / 2, hexToRgb(prim), hexToRgb(sec)),
        transition: 'border 0.34s ease-in-out, border-color 0.15s linear',
}));

export const StyledLogo = styled(Img)({
        width: 54,
        height: 54,
        filter: 'drop-shadow(5px 5px 5px #000)',
        transition: 'filter 0.4s, transform 0.4s ease-in-out',
        "&:hover" : {
            transform: 'scale(1.05) rotate(0.01deg)',
            filter: 'drop-shadow(5px 5px 5px #555)',
    }
});

export function BetLogo(props) {
    return(
        <img {...props} alt={props.alt}
        style={{
        objectFit: 'contain',
        marginTop: 10,
        maxHeight: props.dim,
        overflow: 'hidden',
        position: 'absolute',
        maxWidth: props.dim,
        zIndex: 4,
        transition: 'filter 0.4s, transform 0.4s ease-in-out',
        "&:hover" : {
            transform: 'scale(1.02) rotate(0.01deg)',
        }
        }}/>
    )
}

export const StyledText = styled(Typography)({
        marginTop: 12,
        marginLeft: 40,
        filter: 'drop-shadow(5px 5px 5px #000)',
        transition: 'filter 0.4s',
        "&:hover" : {
            filter: 'drop-shadow(5px 5px 5px #666)',
        }
});

export const TokenButton = styled(Button)({
    background: '#333',
    borderWidth: 1.5,
    borderRadius: '15px',
    transition: 'background 0.4s ease-in-out',
    "&:hover" : {
        background: '#444',
    },
    label: {
        flexDirection: 'inherit',
    },
});

export const LightButton = styled(Button)({
        marginTop: 12,
        marginLeft: 40,
        filter: 'drop-shadow(5px 5px 5px #000)',
        transition: 'filter 0.4s',
        "&:hover" : {
            filter: 'drop-shadow(5px 5px 5px #666)',
        }
});


export const StyledLink = styled(Link) ({
    textDecoration: 'none',
    "&:active" : {
        color: lightSec
    },
    "a:link" : {
        transition: 'color 0.3s linear'
    }
});

//Componeent style definition 
export const StyledButton = styled(Button)((props) => ({
    textTransform: "none",
    borderRadius: '50px',
    whiteSpace: 'nowrap',
    borderColor: props.mycolor,
    backgroundColor: '#000',
    borderWidth: '2px',
    fontSize: props.fontSize,
    transition: '0.3s background ease-in-out',
    filter: 'drop-shadow(5px 5px 5px #000)',
    '&:focus': {
        backgroundColor : "#111",
    },
    '&:hover, &:active' : {
        backgroundColor: darken(props.mycolor, 0.5),
    },

    marginBottom: '15px',
    marginTop: '12px',
    marginLeft: (window.innerWidth > 300) ? (window.innerWidth > 600) ? 30 : 15 : 5,
    color: 'white',

}));

StyledButton.defaultProps = {
    variant : 'outlined',
    mycolor: sec,
    fontSize: 20
};


export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))((props) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: props.color,
    transition: 'background-color 0.5s ease-in-out'
  },
    '& .MuiTabs-flexContainer' : {
      
        justifyContent: 'center',
        fontFamily: 'Oxanium, sans-serif'
    }
}));

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontSize: 35,
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 200, 228, 0.32)',
    },
  }),
);

export const StyledToggleButton = styled(ToggleButton)((props) => ({
    textTransform: "none",
    borderRadius: '50px',
    whiteSpace: 'nowrap',
    borderColor: props.mycolor,
    backgroundColor: '#000',
    borderWidth: '2px',
    fontSize: props.fontSize,
    transition: '0.3s background ease-in-out',
    filter: 'drop-shadow(5px 5px 5px #000)',
    '&:focus': {
        backgroundColor : "#111",
    },
    '&:hover, &:active' : {
        backgroundColor: darken(props.mycolor, 0.5),
    },

    marginBottom: '15px',
    marginTop: '12px',
    marginLeft: (window.innerWidth > 300) ? (window.innerWidth > 600) ? 30 : 15 : 5,
    color: 'white',

}));

StyledToggleButton.defaultProps = {
    mycolor: sec,
    fontSize: 20
};

export const TextBox = styled(Box)((props) => ({

    //background: 'linear-gradient( #112, #111) padding-box, linear-gradient(to right, #7700b3, #b38600) border-box',
    backgroundColor: "#11111177",
    backdropFilter: 'blur(100px)',
    border: '1.2px solid transparent',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginTop: props.mobile ? '5vh' : '10vh',
    marginBottom: '13vh',
    padding: 50,
    borderRadius: 50,

}))


export const StyledTextField = styled(TextField)((props) => ({
    backgroundColor: '#333',
    borderRadius: 20,
    "& .MuiFormLabel-root.Mui-disabled": {
        color: "rgba(0, 0, 0,0.0)"
    },
    "& fieldset": {
        borderRadius: 20,
    },
    "& input": {
        borderRadius: 20,
        "&::after": {
            borderBottomColor: "#664875",
        }
    }
}));

export const StyledDatePicker = styled(DateTimePicker)((props) => ({
    backgroundColor: '#333',
    borderRadius: 20,
    "& .MuiFormLabel-root.Mui-disabled": {
        color: "rgba(0, 0, 0,0.0)"
    },
    "& .MuiCalendarPicker-root": {
        backgroundColor: "black"
    },
    "& fieldset": {
        borderRadius: 20,

    },
    "& input": {
        borderRadius: 20,
        borderColor: prim,
        "&::after": {
            borderColor: "#664875",
        }
    }
}));

StyledTextField.defaultProps = {
    variant: "outlined",
    inputProps: { 
        style: {
            color: "white",
            fontSize: 25,
            "&::after": {
                borderBottomColor: "#664875",
            }
        }} ,
    inputlabelprops: {  
        style: {
            color: "white",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    display: "none"
            },
            fontSize: 25 
        }
    },
    SelectProps: {  
        style: {
            backgroundColor: "black",
        },
    },
};

export function TextFieldProp(font,p = '') {
    const prop = {
    variant: "outlined",
    inputProps: { 
        style: {
            color: "white",
            fontSize: font,
            lineHeight: 1,
            padding: p,
            "&::after": {
                borderBottomColor: "#664875",
            }
        }} ,
    inputlabelprops: {  
        style: {
            color: "white",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    display: "none"
            },
            fontSize: font
        }
    },
    SelectProps: {  
        style: {
            backgroundColor: "black",
        },
    },
    }

    return prop;
};


StyledDatePicker.defaultProps = {
    variant: "outlined",
    inputProps: { 
        style: {
            color: "white",
            fontSize: 25,
            "&::after": {
                borderBottomColor: "#664875",
            }
        }} ,
    inputlabelprops: {  
        style: {
            color: "white",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    display: "none"
            },
            fontSize: 28 
        }
    },
    SelectProps: {  
        style: {
            backgroundColor: "black",
        },
    },
};


export const calendarProp = {
    variant: "outlined",
    inputProps: { 
        style: {
            color: "white",
            fontSize: 28,
            "&::after": {
                borderBottomColor: "#664875",
            },
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    display: "none"
            },
        }
    },
    PaperProps: {
        style: {
            backgroundColor: "#333",
            borderWidth: 10,
            borderColor: "black",
            color: "white",
            "& .MuiButtonBase-root" : {
                backgroundColor: "red"
            }
        }
    },
    componentsProps: {
        style: {
            backgroundColor: "#333",
            color: "white",
            "& .MuiButtonBase-root" : {
                backgroundColor: "red"
            }
        }
    },
    SelectProps: {  
        style: {
            backgroundColor: "black",
        },
    },
};

export const calendarSx = {
  "& .MuiPaper-root": {
    backgroundColor: "#333",
    borderRadius: 10,
  },
  "& .MuiCalendarPicker-root, .MuiClockPicker-root": {
    backgroundColor: "#222",
    borderRadius: 10,
    color: "white"
  },
  "& .MuiTypography-root, .MuiClockNumber-root" : {
    color: "white"
  },
  "& .MuiClockPin" : {
        borderColor: prim
  },
  "& .MuiClockPointer-root": {
    borderColor: prim,
    backgroundColor: prim,
    "& .MuiClockPointer-thumb" : {
        borderColor: prim
    },
  },
  "& .MuiClockPointer-thumb" : {
    borderColor: prim
  },
  
  "& .MuiClock-pin" : {
    backgroundColor: prim
  },
  "& .MuiButtonBase-root.Mui-selected , .PrivatePickersYear-yearButton.Mui-selected": {
    "&:hover" : {
        backgroundColor: sec + '!important'
    },
    color: "rgb(229,228,226)",
    backgroundColor: prim + '!important'
  },
  "& .MuiIconButton-root": {
    backgroundColor: prim,
    "&:hover" : {
        backgroundColor: sec
    },
    "&:selected" : {
        backgroundColor: sec
    },
  }, 
  "button": {
    "&:hover" : {
        backgroundColor: sec
    },
    "&:selected" : {
        backgroundColor: prim
    },
    "&:active" : {
        backgroundColor: prim
    }
  },
    "& .MuiTabs-root": { backgroundColor: "#222" }
};

export const drawerPaperTheme = makeStyles( theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: '#000',
            height: '100%'
    }
}}))

export const theme = createTheme({
    typography: {
        h1: {
            fontSize: 22,
            marginBottom: '15px',
        },
        h6: {
            fontSize: 18,
            marginBottom: '15px',
        }


    },
    palette: {
        primary: {
            main: "#7700b3"
        },
        secondary: {
            main: "#b38600"
        },
    background: {
        defalt: '#222',
        paper : '#222',
    },
    mode : 'dark'
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 300,
            md: 900,
            lg: 1200,
            xl: 1536,
        }
    },

    LocalizationProvider : {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "white",
          // add variant styles like so
          "&.Mui-disabled": {
            "backgroundColor": "#cccccc"
          }
        },
    },
    }
})
