import { formatEther, formatUnits } from '@ethersproject/units'
import useEtherSWR from 'ether-swr'
import {BigNumber, ethers} from 'ethers'
import {useEffect, useState} from 'react'
import { useWeb3React } from '@web3-react/core'
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import {ModularModal} from '../container/SimpleModal'
import {BetSplash, BetSplashSmall, PositionSplash} from '../container/BetSplash'
import {StyledButton, StyledTextField, TextFieldProp} from '../../Style'
import { unixDelta } from '../../utils/time'
import { ColoredBetLogo, ColoredBetLogoSelect } from '../cosmetic/ColoredBetLogo'

import addresses from './json/addresses.json'
import ERC20ABI from './json/ERC20.abi.json'
import MarketPlaceABI from './json/MarketPlace.abi.json'
import UserABI from './json/User.abi.json'
import {Calendar} from './Calendar'
import {IconButton} from '@mui/material'
import { RankedPoolsDocument, LatestPmPoolsDocument, UserPositionsDocument, LatestPoolsDocument, PopularPoolsDocument } from '../../.graphclient'
import { useQuery } from 'urql'

import {gql} from 'urql'
import {useSearchStore} from '../../stores/SearchStore'
import {Fade} from '@material-ui/core'
import {useTagStore} from '../../stores/TagStore'
import {useOrderStore} from '../../stores/OrderStore'
import {useCatStore} from '../../stores/CatStore'

export const CONTRACT_DATA = {
 5777 : [
    {
        abi: ERC20ABI,
        address: addresses["ludo"],
        name: 'Ludocoin',
        symbol: 'LUDO',
        decimals: 18
    },
    {
        abi: MarketPlaceABI,
        address: addresses["market"],
        name: 'Market',
    },
    {
        abi: UserABI,
        address: addresses["user"],
        name: 'User',
    }
  ],
}

export function ABIs() {
    const matrix = CONTRACT_DATA[5777]
    return Object.entries(
        matrix.reduce((memo, item) => {
        return { ...memo, [item.address]: item.abi }
        }, {})
    )
}

export function EthBalance() {
    const { account } = useWeb3React()
    const { data: balance, mutate } =  useEtherSWR(
        ['getBalance', account, 'latest'],
        {
        subscribe: [
            {
            name: 'block',
            on: (event) => {
                if(!event) {
                    // on every block we check if Ether balance has changed by re-fetching
                    mutate(undefined, true)
                }
            }
            }
        ]
        }
    )

    if (!balance) {
        return null;
    }

    return parseFloat(formatEther(balance)).toPrecision(4);
}

export function TokenBalance(){
    const { account } = useWeb3React()
    const address = addresses["ludo"];
    
    const { data: balance, mutate } = useEtherSWR(
        [address, 'balanceOf', account],
        {
        subscribe: [
        {
            name: 'Transfer',
            topics: [null, account],
            on: (
                state,
                amount,
                event
            ) => {
                const update = state.add(amount)
                mutate(update, true) // optimistic update skip re-fetch
            }
        },
        {
            name: 'Transfer',
            topics: [account, null],
            on: (
                state,
                amount,
                event
            ) => {
                const update = state.sub(amount)
                mutate(update, true) // optimistic update skip re-fetch
            }
        }
        ]
    }
    )

    if (!balance) {
        return null;
    }

    return parseFloat(formatUnits(balance, 18)).toPrecision(4);
}

export function PlayerWinnings(){
    const { account } = useWeb3React()
    const address = addresses["user"];
    
    const { data: balance, mutate } = useEtherSWR(
        [address, 'getPlayerWin', account],
        {
        subscribe: [
        {
            name: 'UpdateWinsPlayer',
            topics: [account],
            on: (
                state,
                amount,
                event
            ) => {
                //const update = state.add(amount)
                //mutate(update, true) // optimistic update skip re-fetch
            }
        },
        ]
    }
    )

    if (!balance) {
        return null;
    }

    return parseFloat(formatUnits(balance, 18)).toPrecision(4);
}


export function PmPoolList({account})
{
    const [res, reexecuteQuery] = useQuery({ query: LatestPmPoolsDocument, variables : { creator : account }})
    const { data, fetching, error } = res;

    if(!fetching) {
        return(
            data.pools.map((pool, index) =>
                pool &&
                <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }} key={index}>
                    <BetSplashSmall id={pool.id} address={account}/>
                    <br/>
                </div>
        ))
    } else {
        return null;
    }
}

export function PlayerPoolList({account})
{
    const [res, reexecuteQuery] = useQuery({ query: UserPositionsDocument, variables : { holder : account }})
    const { data, fetching, error } = res;

    if(!fetching) {
        return(
            data.positions.map((position, index) =>
                position &&
                <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center', alignContent: 'center', justifyContent: 'center' }} key={index}>
                    <PositionSplash id={position.id}/>
                    <br/>
                </div>
        ))
    } else {
        return null;
    }
}

export function PoolList()
{
    const [result, reexecuteQuery] = useQuery({
        query: RankedPoolsDocument,
    })
    
    const { data, fetching, error } = result

    if(!fetching && !error) {
        return(
            data.pools.map((pool, index) =>
                pool &&
                <BetSplash key={index} pool={pool.id} id={pool.id}/>
        ))
    } else {
        return null;
    }
}

export function ChronoPoolList()
{
    const [result, reexecuteQuery] = useQuery({
        query: LatestPoolsDocument,
    })
    
    const { data, fetching, error } = result

    if(!fetching && !error) {
        return(
            data.pools.map((pool, index) =>
                pool &&
                <BetSplash key={index} pool={pool.id} id={pool.id}/>
        ))
    } else {
        return null;
    }
}

export function PopularPoolList()
{
    const [result, reexecuteQuery] = useQuery({
        query: PopularPoolsDocument,
    })
    
    const { data, fetching, error } = result

    if(!fetching && !error) {
        return(
            data.pools.map((pool, index) =>
                pool &&
                <BetSplash key={index} pool={pool.id} id={pool.id}/>
        ))
    } else {
        return null;
    }
}

export function SearchPoolList()
{
    const searchText = useSearchStore((state) => state.search)
    const tokens = searchText.split(" ");
    const tags = useTagStore((state) => state.tags)
    const cat = useCatStore((state) => state.cat)
    const isCat = cat !== "";
    const order = useOrderStore((state) => state.order);

    const stringMap = tokens.map(t => { return {"stringsJoined_contains_nocase" : t}});
    const tagMap = tags.map(t => { return {"name_contains" : t }})
    const jsonString= JSON.stringify(stringMap).replace(/"([^"]+)":/g, '$1:')
    const jsonTag= JSON.stringify(tagMap).replace(/"([^"]+)":/g, '$1:')

    let searchQuery;
    
    if(tags && tags.length !== 0) {
        searchQuery = gql`
        query SearchQuery($strings : [String!]!) {
            pools(
                orderBy: ${order}
                orderDirection: desc
                where: {and : ${jsonString.slice(0, jsonString.length - 1)} , { tags_ : { or : ${jsonTag} }} ${ isCat ? ", {cat_ : { name : \"" + cat + "\"}}" : "" } ]}
            ) {
                id
            }
        }
        `
    }
    else {
        searchQuery = gql`
        query SearchQuery($strings : [String!]!) {
            pools(
                orderBy: ${order}
                orderDirection: desc
                where: {and : ${jsonString.slice(0, jsonString.length - 1)} ${ isCat ? ", {cat_ : { name : \"" + cat + "\"}}"  : "" }] }

            ) {
                id
            }
        }
        `
    }


    const [result, reexecuteQuery] = useQuery({
        query: searchQuery,
        variables : { strings : tags }
    })
    
    const { data, fetching, error } = result

    if(!fetching && !error) {
        return(
            data.pools.map((pool, index) =>
                pool &&
                <BetSplash key={index} pool={pool.id} id={pool.id}/>
        ))
    } else {
        return null;
    }
}

export function PoolCreator({setOpen})
{
    const {library} = useWeb3React();

    const [topic, setTopic] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [value, setValue] = useState();
    const [seconds, setSeconds] = useState(Date.now());
    const [date, setDate] = useState(Date.now());
    const [color, setColor] = useState(1);
    const [success, setSuccess] = useState(true);
    const [tags, setTags] = useState("");

    const ALPHA_NUMERIC_DASH_REGEX = /^[.0-9_-]*$/; 
    
    const fields = [
        "Topic",
        "Description",
        "Image Url",
        "Value",
        "Tags"
    ]

    const functionMap = {
        "Topic" : setTopic,
        "Description" : setDescription,
        "Image Url" : setUrl,
        "Value" : setValue,
        "Tags" : setTags
    }

    const valueMap = {
        "Topic" : topic,
        "Description" : description,
        "Image Url" : url,
        "Value" : value,
        "Tags" : tags
    }

    const typeMap = {
        "Topic" : "text",
        "Description" : "text",
        "Image Url" : "text",
        "Value" : "number",
        "Tags" : "text"
    }

    function filterNum(event) {
        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
        }
    }

    function handleTime(time) {
        setDate(time);
        setSeconds(unixDelta(time._d));
        setSuccess(seconds > 0);
    }

    async function publishPool(topic, url, value, deadline) {
        const contract = new ethers.Contract(addresses["market"], MarketPlaceABI, library);
        const contractWithSigner = contract.connect(library.getSigner());
        const tagArray = tags.split(" ");

        await contractWithSigner.createPool(topic, description, url, tagArray, BigNumber.from(color), BigNumber.from(deadline), BigNumber.from(value));
    }

    function clear() {
        setUrl('')
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems : 'center'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems : 'center'}}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems : 'center'}}>

                    <ColoredBetLogoSelect style={{marginBottom: 40}} setColor={setColor} color={color} img_url={url === "" ? "http://localhost:3000/static/media/logo.b1cc8f34e438d9f9fe45.webp" : url} height={120} width={120}/>

                    {
                    fields.map((element, index) =>
                    index === 2 &&
                            <StyledTextField
                            {...index === 2 ? TextFieldProp(18) : TextFieldProp(28)}
                            fullWidth
                            value={valueMap[element]}
                            onKeyDown={typeMap[element] === "number" ? (event) => {filterNum(event)} : () => {} }
                            style={{ marginBottom: 10 }} key={index} label={element} type={typeMap[element]} onChange={(e) => functionMap[element](e.target.value)}
                            InputProps={{endAdornment: (<IconButton onClick={clear}> <ClearIcon/> </IconButton>)}}/>
                                
                    )
                    }

                    {
                    fields.map((element, index) =>
                    index >= 3 && 
                        <StyledTextField
                        {...index === 2 ? TextFieldProp(18) : TextFieldProp(28)}
                        fullWidth
                        value={valueMap[element]}
                        onKeyDown={typeMap[element] === "number" ? (event) => {filterNum(event)} : () => {} }
                        style={{ marginBottom: 10 }} key={index} label={element} type={typeMap[element]} onChange={(e) => functionMap[element](e.target.value)}/>
                    )
                    }
                </div>
                
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems : 'center'}}>
                    {
                    fields.map((element, index) =>
                        index < 2 && 
                        <StyledTextField
                        multiline
                        minRows={index === 1 ? 7 : ''}
                        fullWidth
                        value={valueMap[element]}
                        onKeyDown={typeMap[element] === "number" ? (event) => {filterNum(event)} : () => {} }
                        {...(index === 1 ? TextFieldProp(22,10) : TextFieldProp(28,10))}
                        style={{ marginBottom: 10, marginLeft: 30 }} key={index} label={element} type={typeMap[element]} onChange={(e) => functionMap[element](e.target.value)}/>
                    )
                    }
                </div>
            </div>
            <Calendar date={date} handleTime={handleTime}/>
            <StyledButton style={{marginTop: 20}} variant='outlined' onClick={() => {publishPool(topic, url, value, seconds); setOpen()}}> Create Pool </StyledButton>
        </div>
    )
}

export function PoolModal(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function genericHandle() {
        if(open) {
            handleClose();
        } else {
            handleOpen();
        }
    }

    return(
    <ModularModal width='42%' icon={<AddIcon/>} generichandle={genericHandle} open={open} onClose={handleClose} style={{ backdropFilter: 'blur(4.5px)' }}  mycolor={props.color} buttontext="New Pool">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems : 'center'}}>
            <PoolCreator setOpen={handleClose}/>
        </div>
    </ModularModal>
    )
}
