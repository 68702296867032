import { darkSec, sec, StyledTextField, TextFieldProp } from '../../Style'
import { Search } from '@material-ui/icons'
import {useSearchStore} from '../../stores/SearchStore'
import {IconButton, InputAdornment} from '@material-ui/core'
import ClearIcon from '@mui/icons-material/Clear';
import { isMobile } from 'react-device-detect'
import { useTagStore } from '../../stores/TagStore';
import Tag from '../btn/Tag';
import {CustomTooltip} from '../CustomTooltip';
import { InputBase } from '@mui/material';
import {borderRadius, padding} from '@mui/system';
import { prim } from '../../Style';
import {useState} from 'react';

export function SearchBox(props) {
    const searchText = useSearchStore((state) => state.search)
    const updateText = useSearchStore((state) => state.updateSearch)
    const tags = useTagStore((state) => state.tags)
    const clearTags = useTagStore((state) => state.clearTags);

    function clear() {
        updateText('')
        clearTags()
    }

    const [open,setOpen] = useState(false);

    return(
            <InputBase

                variant='outlined'
                className='mono'
                sx={{
                    fontSize: '5em',
                    transition: 'border 0.5s ease-in-out',
                    border: '2px solid #555' ,
                    borderRightWidth: '0px',
                    borderLeftWidth: '0px',
                    padding: '8px 30px',
                    maxWidth: '45%',
                    backgroundColor: '#ffffff1f',
                    backdropFilter: 'brightness(20%)',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    borderRadius: 20,
                    
                    "&:hover" : {
                        borderColor: sec,
                    },
                }}
                {... isMobile ? TextFieldProp(18) : TextFieldProp(21)}
                label=""
                fullWidth
                onOpen
                size="medium"
                startAdornment={
                    <InputAdornment position="start">
                    { searchText !== "" &&
                        <IconButton position="start" onClick={clear}>
                            <CustomTooltip delay={500} text="Clear" placement="top">
                                <ClearIcon sx={{ marginLeft: -1.6, color: "#aaa" }}/>
                            </CustomTooltip>
                        </IconButton>

                    }
                    { searchText === "" &&
                        <Search style={{ color: "#aaa" }}/>
                    }
                    </InputAdornment>
                }
                endAdornment={
                        <>
                        { tags.map((t) =>
                            <Tag style={{ marginRight: 20 }} text={t}/>
                        )
                        }
                        </>
                }
                value={searchText}
                onChange={(e) => {updateText(e.target.value) } }>
            </InputBase>
    )
    
}
