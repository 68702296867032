import { Box, Button } from '@material-ui/core'
import { TitledList } from '../container/TitledList'

import Article from '@mui/icons-material/Article';
import Token from '@mui/icons-material/Token';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import Map from '@mui/icons-material/Map';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

import { StyledLink } from '../../Style';

export function BottomBar() {

    const pages = [ 'Docs', 'Whitepaper', 'Tokenomics', 'Roadmap'];
    const icons = [ <Article/>, <HistoryEdu/>, <Token/>, <Map/>]
    const social = [ 'X', 'Instagram', 'TikTok', 'Email']
    const socialLinks = [ 'https://twitter.com/Ludocoin_off', 'https://www.instagram.com/ludocoin_official/', 'https://www.facebook.com/people/Ludo-Coin/100078951951031/', 'mailto:team@ludocoin.org' ]
    const socialIcons = [ <TwitterIcon/>, <InstagramIcon/>, <LibraryMusicIcon/>, <EmailIcon/> ]

return (
        <Box style={{  zIndex : 10, paddingTop: 20, paddingBottom: 20, marginTop: 'auto', display: 'flex', justifyContent: 'space-evenly', width: 'calc(100% - 4px)', borderRadius: '20px 20px 0px 0px', border: '2px solid transparent',background: 'linear-gradient(rgb(6, 0, 9), rgba(6, 6, 6, 0)) padding-box, linear-gradient(to right, rgba(119, 0, 179, 0.3), rgba(179, 134, 0, 0.3)) border-box', backgroundColor: '##060009ff'  }}>
            <TitledList title="RESOURCES" style={{ marginBottom: 10}}>
                {pages.map((page, index) => (
                    <StyledLink to={page.toLowerCase()} key={page} style={{ color: 'white'}}>
                        <Button startIcon={icons[index]} style={{color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>{page.toUpperCase()}</Button>
                    </StyledLink>
                ))}
            </TitledList>

            <TitledList title="COMMUNITY" style={{ marginBottom: 10}}>
                {social.map((page, index) => (
                    <a href={socialLinks[index]}  key={page} style={{ textDecoration: 'none', color: 'white'}}>
                        <Button startIcon={socialIcons[index]} style={{color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>{page.toUpperCase()}</Button>
                    </a>
                ))}
            </TitledList>

        </Box>
    )
}
