import {useSpring,animated, config, useSpringRef} from "@react-spring/web";

export default function FadeDiv(props) {
    
    const style2 = useSpring({ ref : props.springRef, config: {duration: props.duration}, from : { opacity: 0.1 }, to : {opacity: 1}})

    return(
        <animated.div style={style2}>
            {props.children}
        </animated.div>
    )
}

FadeDiv.defaultProps = {
    duration : 1500,
}
