import {Tooltip} from '@mui/material';

export function CustomTooltip(props) {
    return(
        <Tooltip enterDelay={props.delay} enterNextDelay={props.delay} arrow={true} placement={props.placement}  title={<div className="mono" style={{ fontSize: 18, filter: 'drop-shadow(7px 7px 7px #000)', marginBottom : 5 }}> {props.text} </div>}>
        {props.children}
        </Tooltip>
    )
}

CustomTooltip.defaultProps = {
    delay : 100,
    placement : "right"
}
