export function trunc(str, n, dots) {
        if (str!== undefined) {
            return str.toString().length > n ? str.toString().substring(0, n - 3) + (dots ? "..." : '') : str.toString();
        }
}

export function filterNum(event) {
        const ALPHA_NUMERIC_DASH_REGEX = /^[0-9]*$/; 

        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
        }
    }
