import { Typography } from "@material-ui/core"

export function TitledList(props) {
    
        return(
            <div style={{ display: 'flex', marginLeft: '5vw', marginRight: '5vw', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography style={{ marginBottom: '1vh', color: '#999'}} variant='h6'>
                    <strong> {props.title} </strong>
                </Typography>
                {props.children}
            </div>
    )

}
