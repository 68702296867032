import { Paper } from '@material-ui/core';
import {IconButton} from '@mui/material';
import {useEffect, useState} from 'react';
import useImageColor from 'use-image-color'

import { BetLogo } from '../../Style'

export function ColoredBetLogo({img_url = "http://localhost:3000/static/media/logo.b1cc8f34e438d9f9fe45.webp", height = 80, width = 80, color_id = 1, style}) {


    const [url, setUrl] = useState();

    const { colors } = useImageColor(img_url, { cors: true, colors: 5 })

    useEffect(() => {
        async function checkImage(url){
         
         const res = await fetch(url);
         const buff = await res.blob();
        
         return buff.type.startsWith('image/')

        }
        
        checkImage(img_url).then((b) => {
            setUrl(b ? img_url : "http://localhost:3000/static/media/logo.b1cc8f34e438d9f9fe45.webp") 
        }
    )},[img_url, setUrl])

    //Reverse because image and title arent alligning
    return ( 
    <>
    {colors && url &&
        <div style={style}>
        <Paper style={{ marginRight: 20, height: height, width: width, position: 'relative', overflow: 'hidden', zIndex: 3, padding: 2, borderRadius: '50%', backgroundColor: colors[color_id], display: 'flex', justifyContent: 'center' }}>
            <BetLogo alt="bet-icon" dim={height-20} src={url}/>
        </Paper>
        </div>
    }
    </>
    )
}

export function ColoredBetLogoSelect({img_url = "http://localhost:3000/static/media/logo.b1cc8f34e438d9f9fe45.webp", height = 80, width = 80, setColor, color, style}) {


    const [url, setUrl] = useState();

    const { colors } = useImageColor(img_url, { cors: true, colors: 5 })

    console.log(colors)

    useEffect(() => {
        async function checkImage(url){
         
         const res = await fetch(url);
         const buff = await res.blob();
        
         return buff.type.startsWith('image/')

        }
        
        checkImage(img_url).then((b) => {
            setUrl(b ? img_url : "http://localhost:3000/static/media/logo.b1cc8f34e438d9f9fe45.webp") 
        }
    )},[img_url, setUrl])

    return ( 
    <>
    {colors && url &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 20}}>
        <Paper style={{ marginRight: 20, height: height, width: width, position: 'relative', overflow: 'hidden', zIndex: 3, padding: 2, borderRadius: '50%', backgroundColor: colors[color], display: 'flex', justifyContent: 'center' }}>
            <BetLogo alt="bet-icon" dim={height-20} src={url}/>
        </Paper>
        <div style={{display: 'flex', marginTop: 20, justifyContent : 'left'}}>
            {
            colors.map((element, index) =>
            <IconButton key={index} onClick={() => {setColor(index)}}>
                <div
                    style={{
                    width: 20,
                    height: 20,
                    backgroundColor: element || "blue"
                }}/>
            </IconButton>
            )
            }
        </div>
        </div>
    }
    </>
    )
}


