import {useNotifStore} from './stores/NotifStore'
import Notif from "./components/Notif"

export default function NotifManager() {
    const notifs = useNotifStore((state) => state.notifs)

    return(
        <>
            { notifs.map((t,index) =>
                <Notif {...t} index={index}/>
            )
            }
        </>
    )
}
