import './App.css';
import loadable from '@loadable/component'

import {MuiThemeProvider, ThemeProvider} from "@material-ui/core/styles"
import 'fontsource-roboto'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { prim, sec, theme } from './Style'
import { TopBar } from './components/bar/TopBar'
import { BottomBar } from './components/bar/BottomBar'
import ScrollToTop from './utils/ScrollToTop'
import WalletProvider from './web3/hook.js'
import {
  Web3Provider
} from '@ethersproject/providers'
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import {ABIs} from './components/chain/Balance';
import {EtherSWRConfig} from 'ether-swr';
import { Client, Provider, fetchExchange, useQuery } from 'urql';
import { graphExchange } from '@graphprotocol/client-urql'
import { cacheExchange } from '@urql/exchange-graphcache';
import * as GraphClient from './.graphclient'
import CookieIcon from '@mui/icons-material/Cookie';

const client = new Client({
  url: 'http://localhost:8000/subgraphs/name/ludocoin/ludocoin-subgraph',
  exchanges: [graphExchange(GraphClient), cacheExchange({}), fetchExchange],
});

const Home = loadable(() => import('./pages/Home'))
const Roadmap = loadable(() => import('./pages/Roadmap'))
const Tokenomics = loadable(() => import('./pages/Tokenomics'))
const Litepaper = loadable(() => import('./pages/Litepaper'))
//const Docs = loadable(() => import('./pages/Docs'))
const Dapp = loadable(() => import('./pages/Dapp'))
const DappPlaceholder = loadable(() => import('./pages/DappPlaceholder'))
const PoolPage = loadable(() => import('./pages/PoolPage'))
const UserPage = loadable(() => import('./pages/UserPage'))
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { useCallback, useMemo } from 'react';
import { loadFireflyPreset } from 'tsparticles-preset-firefly';
import NotifManager from './NotifManager';
import {PanElement} from './components/container/PanElement';
import {isMobile} from 'react-device-detect';
import {useSearchStore} from './stores/SearchStore';
import {useCatStore} from './stores/CatStore';
import {Tag} from '@mui/icons-material';
import Notif from './components/Notif';
import {useNotifStore} from './stores/NotifStore';

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

function App() {

    const { library } = useWeb3React();
    const addNotif = useNotifStore((state) => state.addNotif)
    if(!window.localStorage.getItem("cookie")) {
        addNotif({ action: () => { window.localStorage.setItem("cookie", true) }, text : "We only use necessary cookies. By clicking this text, you agree to them.", time : 4001, icon : <CookieIcon style={{ marginRight : 15, marginTop: 5 }}/>});
    }

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
        await loadFireflyPreset(engine)
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (
        <>
        <Particles
            id="tsparticles"
            style={{ filter: 'blur(35px)' }}
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                preset: "firefly",
            interactivity : {
            events: {
                onHover: {
                    enable: true,
                    mode: "attract"
                },
                    resize: true,
                },
                modes: {
                    trail: {
                        delay: 0.5,
                        quantity: 40,
                    },
            push: {
              quantity: 4
            },
            attract: {
              distance: 5000,
              quantity: 2000,
              duration: 4,
              pauseOnStop: false
          }
                },
        },
                background: {
                        color: {
                            value: "#060009",
                        },
                },
                particles: {
        number: {
            value: 20,
        },
        color: {
            value: [sec, prim],
        },
        shape: {
            type: "square",
        },

        life: {
            duration: {
                value: 100,
                sync: false,
            },
            count: 500,
        },
        opacity: {
          value: { min: 0, max: 1 },
          animation: {
          count: 10,
          enable: true,
          speed: 0.5,
          startValue: "min",
          sync: false
        }
        },
        size: {
            value: {
                min: 110,
                max: 160,
            },
        },
        move: {
            enable: true,
            speed: 3,
            random: false,
            size: true,
        },
    },
            }}
        />
    );
    <ThemeProvider theme={theme}>
    <Provider value={client}>
    <Web3ReactProvider getLibrary={getLibrary}>
    <WalletProvider>

    <EtherSWRConfig
                value={{
                    web3Provider: library,
                    ABIs: new Map(ABIs()),
                    refreshInterval: 10000
                }}>


    <Router>
    <ScrollToTop/>
        <div className="App">
            <header style={{ overflowX: 'hidden' }} className="App-header">

            <TopBar/>
            <NotifManager/>
            
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/home' element={<Home />} />
                <Route exact path='/roadmap' element={<Roadmap />} />
                <Route exact path='/token' element={<Tokenomics />} />
                <Route exact path='/litepaper' element={<Litepaper />} />
                <Route exact path='/dappdev' element={<Dapp />} />
                <Route exact path='/dapp' element={<DappPlaceholder/>} />
                <Route path="/pool/:poolId" element={<PoolPage/>} />
                <Route path="/user/:userId" element={<UserPage/>} />
            </Routes>

            <BottomBar/>

            </header>
        </div>
    </Router>
    </EtherSWRConfig>
    </WalletProvider>
    </Web3ReactProvider>
    </Provider>
    </ThemeProvider>
    </>
    );
}

export default App;
