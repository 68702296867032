import {prim, sec} from '../Style'

export function TextBoxGlass(props) {
    return(
        <div style={props.style}>
        <div style={{
              borderRadius: props.radius,
              overflow: 'hidden',
              transition: 'transform 0.3s ease-in-out',
            "&:hover" : {
                transform: 'scale(1.03) rotate(0.01deg)',
            },

        }}>
        <div style={{
            border: '2.5px solid',
            borderRadius: props.radius,
            borderImage: 'linear-gradient(45deg,' + prim + ',' + sec + ') 1',
            display: 'flex',
            filter: 'drop-shadow(7px 7px 7px #111)',
            flexWrap: 'wrap',
            flexDirection: props.direction,
            padding: props.padding,
            backgroundColor: '#060009' + props.transparency,
            backdropFilter: 'blur(' + props.blur + 'px)'
        }}>
                {props.children}
        </div>
        </div>
        </div>
    )   
}

TextBoxGlass.defaultProps = {
    transparency : '77',
    blur: "50",
    radius : 40,
    direction : 'column',
    padding : 50
}
